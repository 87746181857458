import React from 'react';
import { Box, Card, Typography, Link } from '@mui/material';
import PropTypes from 'prop-types';

const OwnerSection = ({ electricCharger }) => {
  const { OrganisationName, Website, TelephoneNo } = electricCharger.DeviceOwner;

  return (
    <Card sx={{ m: 2 }}>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6">🪧 Owner</Typography>
        <Typography>Organisation Name: {OrganisationName}</Typography>
        <Typography>Website: <Link href={Website}>{Website}</Link></Typography>
        <Typography>Phone No: <Link href={`tel:${TelephoneNo.trim()}`}>{TelephoneNo}</Link></Typography>
      </Box>
    </Card>
  );
};

OwnerSection.propTypes = {
  electricCharger: PropTypes.shape({
    DeviceOwner: PropTypes.shape({
      OrganisationName: PropTypes.string.isRequired,
      Website: PropTypes.string.isRequired,
      TelephoneNo: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OwnerSection;