import React from 'react';
import { Card, Box, Typography, Stack, Link } from '@mui/material';
import PropTypes from 'prop-types';

const ConnectorItem = ({ connector, index }) => (
  <Stack key={index}>
    <Typography>#{index + 1}</Typography>
    <Typography>Type: {connector.ConnectorType}</Typography>
    <Typography>Status: {connector.ChargePointStatus}</Typography>
    <Typography>Output (kW): {connector.RatedOutputkW}</Typography>
    <Typography>Output (Voltage): {connector.RatedOutputVoltage}</Typography>
    <Typography>Output (Current): {connector.RatedOutputCurrent}</Typography>
    <Typography>Charge Method: {connector.ChargeMethod}</Typography>
    <Typography>Tethered: {connector.TetheredCable ? "Yes" : "No"}</Typography>
    <Box sx={{ m: 2 }} />
  </Stack>
);

const ConnectorsSection = ({ electricCharger }) => {
  return (
      <Card sx={{ m: 2 }}>
        <Box sx={{ m: 2 }}>
          <Typography variant="h6">🔌 Connectors</Typography>
          {electricCharger.Connector.map((connector, index) => (
            <ConnectorItem key={index} connector={connector} index={index} />
          ))}
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              Confused? Check out this handy guide from the{' '}
              <Link target="_blank" rel="noreferrer" href="https://energysavingtrust.org.uk/advice/charging-electric-vehicles/">
                Energy Saving Trust
              </Link>
            </Typography>
          </Box>
        </Box>
      </Card>
  );
};

ConnectorsSection.propTypes = {
  electricCharger: PropTypes.shape({
    Connector: PropTypes.arrayOf(PropTypes.shape({
      ConnectorType: PropTypes.string.isRequired,
      ChargePointStatus: PropTypes.string.isRequired,
      RatedOutputkW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      RatedOutputVoltage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      RatedOutputCurrent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      ChargeMethod: PropTypes.string.isRequired,
      TetheredCable: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
};

export default ConnectorsSection;