import React, { useState, useCallback } from 'react';
import { Drawer, List } from '@mui/material';
import PropTypes from 'prop-types';
import LocationSection from './LocationSection';
import ConnectorsSection from './ConnectorsSection';
import PaymentSection from './PaymentSection';
import OwnerSection from './OwnerSection';
import ControllerSection from './ControllerSection';
import ActionSection from './ActionSection';

const ElectricChargerDetailDrawer = ({ electricCharger, onMapsDirectionsClicked, onDrawerClosed }) => {
  const [open, setOpen] = useState(true);

  const closeDrawer = useCallback((event) => {
    setOpen(false);
    onDrawerClosed();
  }, [onDrawerClosed]);

  const sections = [
    { Component: ActionSection, extraProps: { onMapsDirectionsClicked, onCloseChargerInfo: closeDrawer } },
    { Component: LocationSection },
    { Component: ConnectorsSection },
    { Component: PaymentSection },
    { Component: OwnerSection },
    { Component: ControllerSection },
  ];

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      ModalProps={{
        BackdropProps: {
          style: { backgroundColor: 'transparent' }
        }
      }}
    >
      <List>
        {sections.map(({ Component, extraProps = {} }, index) => (
          <Component 
            key={index}
            electricCharger={electricCharger}
            {...extraProps}
          />
        ))}
      </List>
    </Drawer>
  );
};

ElectricChargerDetailDrawer.propTypes = {
  electricCharger: PropTypes.object.isRequired,
  onMapsDirectionsClicked: PropTypes.func.isRequired,
  onDrawerClosed: PropTypes.func.isRequired,
};

export default ElectricChargerDetailDrawer;