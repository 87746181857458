import React, { useState, useEffect, useCallback } from 'react';
import MapView from './features/map/components/MapView.js';
import NavBar from './features/navbar/components/NavBar.js';
import ElectricChargerDetail from './features/chargerdetail/components/ElectricChargerDetail.js';
import { Stack } from '@mui/material';
import AboutDialog from './features/navbar/components/AboutDialog.js';
import ElectricChargerDetailDrawer from './features/chargerdetail/components/ElectricChargerDetailDrawer.js';
import { useMediaQuery } from 'react-responsive';
import FiltersDrawer from './features/filters/components/FiltersDrawer.js';
import Filters from './features/filters/components/Filters.js';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme.js'

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

const appleMapsUrl = "http://maps.apple.com/?t=m&dirflg=d&daddr=";
const googleMapsUrl = "https://www.google.com/maps/dir/?api=1&destination=";

const App = () => {
  const [state, setState] = useState({
    searchQuery: '',
    electricPoints: [],
    connectorTypes: [],
    connectorTypeId: '',
    flyToOccurred: false,
    selectedElectricCharger: null,
    showAboutModal: false,
    showFilters: false,
    priceType: '',
    kilowattSpeed: '',
    filterAnchor: null,
    loading: false,
  });

  const isDesktop = useMediaQuery({ minWidth: 1224 });

  useEffect(() => {
    getConnectorTypes();
  }, []);

  const getConnectorTypes = async () => {
    try {
      const response = await fetch(new URL(baseUrl + '/electricConnectorTypes'));
      const data = await response.json();
      setState(prev => ({ ...prev, connectorTypes: data.ConnectorType }));
    } catch (error) {
      console.error('Error fetching connector types:', error);
    }
  };

  const getElectricPointsForSearchQuery = useCallback(async (searchQuery) => {
    const url = new URL(`${baseUrl}/electricPoints?searchQuery=${searchQuery}`);
    if (state.connectorTypeId) {
      url.searchParams.append('connector-type-id', state.connectorTypeId);
    }
    return await fetch(url);
  }, [state.postcode]);

  const onSearchButtonTapped = useCallback(async (searchQuery) => {
    setState(prev => ({
      ...prev,
      searchQuery: searchQuery,
      electricPoints: [],
      flyToOccurred: false,
      loading: true,
      drawerOpen: false,
    }));

    try {
      const response = await getElectricPointsForSearchQuery(searchQuery);
      const data = await response.json();
      setState(prev => ({
        ...prev,
        electricPoints: data.ChargeDevice,
        loading: false,
      }));
    } catch (error) {
      console.error('Error fetching electric points:', error);
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [getElectricPointsForSearchQuery]);

  const onFilterTapped = useCallback((anchor) => {
    setState(prev => ({ ...prev, showFilters: true, filterAnchor: anchor }));
  }, []);

  const onCloseFilters = useCallback(() => {
    setState(prev => ({ ...prev, showFilters: false, filterAnchor: null }));
  }, []);

  const onAboutTapped = useCallback(() => {
    setState(prev => ({ ...prev, showAboutModal: true }));
  }, []);

  const onCloseAboutDialog = useCallback(() => {
    setState(prev => ({ ...prev, showAboutModal: false }));
  }, []);

  const onElectricChargerClicked = useCallback((electricCharger) => {
    setState(prev => ({ ...prev, selectedElectricCharger: electricCharger }));
  }, []);

  const onMapMoved = useCallback(() => {
    setState(prev => ({ ...prev, flyToOccurred: true }));
  }, []);

  const onCloseChargerInfo = useCallback(() => {
    setState(prev => ({ ...prev, selectedElectricCharger: null }));
  }, []);

  const onMapsDirectionsClicked = useCallback((postcode, platform) => {
    const url = platform === "apple" ? appleMapsUrl + postcode : googleMapsUrl + postcode;
    window.open(url, "_blank");
  }, []);

  const onPriceFilterSelected = useCallback((price) => {
    setState(prev => ({ ...prev, priceType: price }));
  }, []);

  const onConnectorFilterSelected = useCallback((connectorTypeId) => {
    setState(prev => ({ ...prev, connectorTypeId }));
  }, []);

  const onKilowattSpeedFilterSelected = useCallback((kilowattSpeed) => {
    setState(prev => ({ ...prev, kilowattSpeed }));
  }, []);

  const onClearFilterClicked = useCallback(() => {
    setState(prev => ({
      ...prev,
      connectorTypeId: '',
      priceType: '',
      kilowattSpeed: ''
    }));
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <Stack sx={{ height: "100%" }}>
      <NavBar
        loading={state.loading}
        onSearchButtonTapped={onSearchButtonTapped}
        onFilterTapped={onFilterTapped}
        onAboutTapped={onAboutTapped}
      />
      <MapView
        electricPoints={state.electricPoints}
        flyToOccurred={state.flyToOccurred}
        chargerSelected={state.selectedElectricCharger != null}
        onMapMoved={onMapMoved}
        onElectricChargerClicked={onElectricChargerClicked}
        filteredPrice={state.priceType}
        filteredConnectorType={state.connectorTypeId}
        filteredChargingSpeed={state.kilowattSpeed}
        isDesktop={isDesktop}
      />
      {state.showAboutModal && <AboutDialog open={state.showAboutModal} closeDialog={onCloseAboutDialog} />}
      {state.selectedElectricCharger && (
        <div id="electric-charger-detail">
          {isDesktop ? (
            <ElectricChargerDetailDrawer
              electricCharger={state.selectedElectricCharger}
              onDrawerClosed={onCloseChargerInfo}
              onMapsDirectionsClicked={onMapsDirectionsClicked}
            />
          ) : (
            <ElectricChargerDetail 
              electricCharger={state.selectedElectricCharger}
              onCloseChargerInfo={onCloseChargerInfo}
              onMapsDirectionsClicked={onMapsDirectionsClicked}
            />
          )}
        </div>
      )}
      {state.showFilters && (
        <div id="filters">
          {isDesktop ? (
            <FiltersDrawer
              showFilters={state.showFilters}
              onCloseFilters={onCloseFilters}
              connectorTypes={state.connectorTypes}
              filteredConnectorTypeId={state.connectorTypeId}
              onConnectorFilterSelected={onConnectorFilterSelected}
              filteredPrice={state.priceType}
              onPriceFilterSelected={onPriceFilterSelected}
              filteredKilowattSpeed={state.kilowattSpeed}
              onKilowattSpeedFilterSelected={onKilowattSpeedFilterSelected}
              onClearFilterClicked={onClearFilterClicked}
            />
          ) : (
            <Filters
              showFilters={state.showFilters}
              onCloseFilters={onCloseFilters}
              connectorTypes={state.connectorTypes}
              filteredConnectorTypeId={state.connectorTypeId}
              onConnectorFilterSelected={onConnectorFilterSelected}
              filteredPrice={state.priceType}
              onPriceFilterSelected={onPriceFilterSelected}
              filteredKilowattSpeed={state.kilowattSpeed}
              onKilowattSpeedFilterSelected={onKilowattSpeedFilterSelected}
              onClearFilterClicked={onClearFilterClicked}
            />
          )}
        </div>
      )}
    </Stack>
    </ThemeProvider>
  );
};

export default App;