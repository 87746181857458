import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import LocationSection from './LocationSection';
import ConnectorsSection from './ConnectorsSection';
import OwnerSection from './OwnerSection';
import ControllerSection from './ControllerSection';
import PaymentSection from './PaymentSection';
import ActionSection from './ActionSection';
import PropTypes from 'prop-types';

const ElectricChargerDetail = ({ electricCharger, onMapsDirectionsClicked, onCloseChargerInfo }) => {
  const sections = [
    { Component: ActionSection, extraProps: { onMapsDirectionsClicked, onCloseChargerInfo } },
    { Component: LocationSection },
    { Component: ConnectorsSection },
    { Component: PaymentSection },
    { Component: OwnerSection },
    { Component: ControllerSection },
  ];

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      {sections.map(({ Component, extraProps = {} }, index) => (
        <Grid xs={12} key={index}>
          <Component electricCharger={electricCharger} {...extraProps} />
        </Grid>
      ))}
    </Grid>
  );
};

ElectricChargerDetail.propTypes = {
  electricCharger: PropTypes.object.isRequired,
  onMapsDirectionsClicked: PropTypes.func.isRequired,
  onCloseChargerInfo: PropTypes.func.isRequired,
};

export default ElectricChargerDetail;