import React from 'react';
import { Stack, Button } from '@mui/material';
import PropTypes from 'prop-types';

const ActionSection = ({ electricCharger, onMapsDirectionsClicked, onCloseChargerInfo }) => {
  const postcode = electricCharger.ChargeDeviceLocation.Address.PostCode;

  const handleClick = (e, action, platform) => {
    e.stopPropagation();
    action(postcode, platform);
  };

  return (
      <Stack direction="row" spacing={1} sx={{ m: 2 }}>
        <Button 
          size="small" 
          sx={{ width: 1 }} 
          onClick={(e) => handleClick(e, onMapsDirectionsClicked, "apple")} 
          variant="contained"
        >
          Directions via Apple Maps
        </Button>

        <Button 
          size="small" 
          sx={{ width: 1 }} 
          onClick={(e) => handleClick(e, onMapsDirectionsClicked, "google")} 
          variant="contained"
        >
          Directions via Google Maps
        </Button>

        <Button 
          size="small" 
          sx={{ width: 1 }} 
          onClick={(e) => {
            e.stopPropagation();
            onCloseChargerInfo();
          }} 
          variant="contained"
        >
          Back
        </Button>
      </Stack>
  );
};

ActionSection.propTypes = {
  electricCharger: PropTypes.shape({
    ChargeDeviceLocation: PropTypes.shape({
      Address: PropTypes.shape({
        PostCode: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onMapsDirectionsClicked: PropTypes.func.isRequired,
  onCloseChargerInfo: PropTypes.func.isRequired,
};

export default ActionSection;