import React from 'react';
import { Stack, Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const LocationSection = ({ electricCharger }) => {
  const { Address } = electricCharger.ChargeDeviceLocation;
  const { Street, BuildingName, PostTown, County, PostCode } = Address;

  const showBuildingName = BuildingName && BuildingName.trim() !== "";

  return (
    <Card sx={{ m: 2 }}>
      <Stack sx={{ m: 2 }}>
        <Typography variant="h6">🗺️ Address</Typography>
        <Typography>{Street}</Typography>
        {showBuildingName && (
          <Typography variant="body1">Building Name: {BuildingName}</Typography>
        )}
        <Typography variant="body1">{PostTown}</Typography>
        <Typography variant="body1">{County}</Typography>
        <Typography variant="body1">{PostCode}</Typography>
      </Stack>
    </Card>
  );
};

LocationSection.propTypes = {
  electricCharger: PropTypes.shape({
    ChargeDeviceLocation: PropTypes.shape({
      Address: PropTypes.shape({
        Street: PropTypes.string.isRequired,
        BuildingName: PropTypes.string,
        PostTown: PropTypes.string.isRequired,
        County: PropTypes.string.isRequired,
        PostCode: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default LocationSection;
