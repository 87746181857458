import React from 'react';
import { Box, Card, Typography, Link } from '@mui/material';
import PropTypes from 'prop-types';

const PaymentSection = ({ electricCharger }) => {
  const {
    PaymentRequiredFlag,
    PaymentDetails,
    SubscriptionRequiredFlag,
    SubscriptionDetails,
    ParkingFeesFlag,
    ParkingFeesDetails,
    ParkingFeesUrl
  } = electricCharger;

  const renderFeeDetails = (flag, details, label) => {
    if (!flag) return null;
    return (
      <Typography variant="body1">
        {label}: <Link href={details}>{details}</Link>
      </Typography>
    );
  };

  return (
    <Card sx={{ m: 2 }}>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6">💷 Fees</Typography>
        <Typography variant="body1">Payment Required: {PaymentRequiredFlag ? 'Yes' : 'No'}</Typography>
        {renderFeeDetails(PaymentRequiredFlag, PaymentDetails, 'Payment Details')}

        <Typography variant="body1">Subscription Required: {SubscriptionRequiredFlag ? 'Yes' : 'No'}</Typography>
        {renderFeeDetails(SubscriptionRequiredFlag, SubscriptionDetails, 'Subscription Details')}

        <Typography variant="body1">Parking Fees: {ParkingFeesFlag ? 'Yes' : 'No'}</Typography>
        {renderFeeDetails(ParkingFeesFlag, ParkingFeesDetails, 'Parking Fees Details')}
        {renderFeeDetails(ParkingFeesFlag, ParkingFeesUrl, 'Parking Fees Url')}
      </Box>
    </Card>
  );
};

PaymentSection.propTypes = {
  electricCharger: PropTypes.shape({
    PaymentRequiredFlag: PropTypes.bool.isRequired,
    PaymentDetails: PropTypes.string,
    SubscriptionRequiredFlag: PropTypes.bool.isRequired,
    SubscriptionDetails: PropTypes.string,
    ParkingFeesFlag: PropTypes.bool.isRequired,
    ParkingFeesDetails: PropTypes.string,
    ParkingFeesUrl: PropTypes.string
  }).isRequired,
};

export default PaymentSection;