import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    width: '16em',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
  }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.0),
    transition: theme.transitions.create('width'),
  },
}));

const placeholders = [
  "Try searching for E1 0AA",
  "Try searching for Manchester",
  "Try searching for M1 1AD",
  "Try searching for London",
  "Try searching for Liverpool",
  "Try searching for EH 1BL",
  "Try searching for NW3 1HA",
  "Try searching for B1 1LJ",
  "Try searching for Edinburgh",
  "Try searching for Birmingham",
];

const AnimatedSearchBar = ({ onChange, onSearch, interval }) => {
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (inputValue) return; // Don't animate if there's input

    const timer = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, interval);

    return () => clearInterval(timer);
  }, [placeholders, interval, inputValue]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Search>
      <StyledInputBase
        placeholder={placeholders[placeholderIndex]}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={(e) => e.key === 'Enter' && onSearch}
      />
    </Search>
  );
};

export default AnimatedSearchBar;
