import React from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Button } from '@mui/material';
import PropTypes from 'prop-types';

const AboutDialog = ({ open, closeDialog }) => {
  return (
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>EV Buddy ({process.env.REACT_APP_VERSION})</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A map built to show electric vehicle charging locations.
            <br />
            Data provided by the{' '}
            <a target="_blank"
               rel="noopener noreferrer"
               href="https://www.gov.uk/guidance/find-and-use-data-on-public-electric-vehicle-chargepoints"
            > National Chargepoint Registry
            </a>
            .
            <br />
            Built and maintained by{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://darrylbayliss.net">
              Darryl Bayliss
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
  );
};

AboutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default AboutDialog;