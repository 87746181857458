import { React } from 'react'
import { Box, Card, Typography, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material'
import { theme } from '../../../theme'

const KilowattSpeedFilterSection = ({ filteredKilowattSpeed, onKilowattSpeedFilterSelected }) => {
    
    const chargingSpeeds = new Map([
        ["<3.7kW", "Low (0 - < 3.7 kWh)"],
        [">=3.7kWh", "Standard (3.7 kW - <8 kWh)"],
        [">=8kWh", "Fast (8 kW - 49 kWh)"],
        [">=50kWh", "Rapid (50 kW - 149 kWh)"],
        ["150kWh", "Ultra-Rapid (> 150 kWh)"]
    ]);

    return (
            <Card sx={{ m: 2 }}>
                <Box sx={{ m: 2 }}>
                    <Typography variant="h6">
                        ⚡️ Charging Speeds 
                        <Link 
                            target="_blank" 
                            rel="noreferrer" 
                            href="https://energysavingtrust.org.uk/advice/charging-electric-vehicles#jumpto-4">
                            {"?"}
                        </Link>
                    </Typography>
                    <RadioGroup
                        column
                        value={filteredKilowattSpeed}
                        onChange={(event) => onKilowattSpeedFilterSelected(event.target.value)}
                    >
                        {Array.from(chargingSpeeds).map(([key, value]) => (
                            <FormControlLabel
                                key={key}
                                control={<Radio />}
                                value={key}
                                label={value}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </Card>
    );
}

export default KilowattSpeedFilterSection