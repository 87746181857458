import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Button,
  Box,
  CircularProgress,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AnimatedSearchBar from './AnimatedSearchBar';
import { KofiButton } from 'react-kofi-button';

const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  height: 36,
  marginLeft: theme.spacing(1),
}));

const NavBar = ({ onSearchButtonTapped, onAboutTapped, onFilterTapped, loading }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearch = () => {
    onSearchButtonTapped(searchQuery);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    action();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          EV Buddy
        </Typography>
        <LoadingWrapper>
          {loading && <CircularProgress color="inherit" size={24} />}
        </LoadingWrapper>
        <AnimatedSearchBar 
          onChange={setSearchQuery}
          onKeyDown={handleSearch}
          interval={5000}
        />
        <Button onClick={handleSearch} variant="contained" sx={{ marginLeft: 1 }}>
          Search
        </Button>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ marginLeft: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick(onFilterTapped)}>Filter</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(onAboutTapped)}>About</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button onClick={onFilterTapped} variant="contained" sx={{ marginLeft: 1 }}>
              Filter
            </Button>
            <Button onClick={onAboutTapped} variant="contained" sx={{ marginLeft: 1 }}>
              About
            </Button>
            <Box sx={{ marginLeft: 1 }}>
              <KofiButton username="DarrylBayliss" preset="thin" label="Support This Site" backgroundColor="kofiRed" />
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  onSearchButtonTapped: PropTypes.func.isRequired,
  onAboutTapped: PropTypes.func.isRequired,
  onFilterTapped: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NavBar;