import React, { PureComponent } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import theme from '../../../theme'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

// Hacky code to add a click listener to mapboxgl markers: https://github.com/mapbox/mapbox-gl-js/issues/7793#issuecomment-829566841
mapboxgl.Marker.prototype.onClick = function(handleClick) {
  this._handleClick = handleClick;
  return this;
};

mapboxgl.Marker.prototype._onMapClick = function(t) {
  const targetElement = t.originalEvent.target;
  const element = this._element;
  if (this._handleClick && (targetElement === element || element.contains((targetElement)))) {
    this._handleClick();
  }
};

class MapView extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      lat: 53.47986,
      lng: -4.38236,
      zoom: 5
    }

    this.mapContainer = React.createRef()
    this.map = React.createRef()
    this.markers = React.createRef()
  }

  componentDidMount () {
    if (this.map.current) {
      return
    }

    const { lng, lat, zoom } = this.state
    this.map.current = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom
    })

    this.map.current.on('move', () => {
      this.setState({
        lng: this.map.current.getCenter().lng.toFixed(4),
        lat: this.map.current.getCenter().lat.toFixed(4),
        zoom: this.map.current.getZoom().toFixed(2)
      })
    })

    this.map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
  }

  render () {
    if (this.markers.current != null) {
      this.markers.current.forEach((marker) => (
        marker.remove()
      ))
    }

    const electricPoints = this.props.electricPoints
    const flyToOccurred = this.props.flyToOccurred
    const chargerSelected = this.props.chargerSelected
    const filteredConnectorType = this.props.filteredConnectorType
    const filteredPrice = this.props.filteredPrice
    const filteredChargingSpeed = this.props.filteredChargingSpeed
    const isDesktop = this.props.isDesktop

    const matchesConnectorId = ((connector) => connector.ConnectorType === filteredConnectorType)

    const markers = electricPoints.filter((electricPoint) => {

      var matchesCondition = true

      if (filteredConnectorType != '') {
        matchesCondition = electricPoint.Connector.some(matchesConnectorId) 
      }

      if (filteredPrice != '') {
        var isPaid = filteredPrice != 'Free'
        matchesCondition = matchesCondition && electricPoint.PaymentRequiredFlag === isPaid
      } 

      if (filteredChargingSpeed != '') {

        const matchesChargingSpeed = (connector) => {
          const powerKW = connector.RatedOutputkW;
          switch (filteredChargingSpeed) {
            case '<3.7kWh':
              return powerKW < 3.7;
            case '>=3.7kWh':
              return powerKW >= 3.7 && powerKW < 8;
            case '>=8kWh':
              return powerKW >= 8 && powerKW < 50;
            case '>=50kWh':
              return powerKW >= 50 && powerKW < 150;
            case '150kWh':
              return powerKW >= 150;
            default:
              return false;
          }
        };

        matchesCondition = matchesCondition && electricPoint.Connector.some(matchesChargingSpeed)
      }
      
      return matchesCondition

    }).map((electricPoint) => (
      new mapboxgl.Marker({"color": theme.palette.primary.main})
      .onClick(() => {
        this.handleMarkerClick(electricPoint, isDesktop)
      }
      )
        .setLngLat([electricPoint.ChargeDeviceLocation.Longitude, electricPoint.ChargeDeviceLocation.Latitude])
        .addTo(this.map.current)
    ))

    this.markers.current = markers

    if (electricPoints.length > 0 && !flyToOccurred) {

      // Add markers to the map
      const bounds = new mapboxgl.LngLatBounds();

      markers.forEach(marker => {
        bounds.extend([marker.getLngLat().lng, marker.getLngLat().lat]);
      });

      // Fit the map to the bounds
      this.map.current.fitBounds(bounds, {
        padding: 50 // Add some padding around the bounding box
      });

      this.props.onMapMoved()
    }

    var mapView = "mapview"

    if (chargerSelected && window.width < 600) {
      mapView = "mapview-condensed"
    } 

    return (<div ref={this.mapContainer} id={mapView}/>)
  }

  handleMarkerClick(electricPoint, isDesktop) {

    this.props.onElectricChargerClicked(electricPoint)

    const markerCoordinates = {
      lng: electricPoint.ChargeDeviceLocation.Longitude,
      lat: electricPoint.ChargeDeviceLocation.Latitude
    }

    const markerOffset = isDesktop ? [-200, 0] : [0, -350]

    this.map.current.flyTo({
      center: markerCoordinates,
      offset: markerOffset,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      zoom: 17,
      speed: 0.75
    })
  }
}

export default MapView
